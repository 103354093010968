import { doc, p } from '@atlaskit/adf-utils/builders';

import { htmlToADF } from '@atlassian/html-adf-transformer';
import { type ContentType } from '@atlassian/ai-issue-create';

import type { IssueCreateSidePanelActions } from '@confluence/issue-create-side-panel';
import { fg } from '@confluence/feature-gating';
import {
	BULK_ISSUE_CREATE_SIDE_PANEL_ID,
	BULK_ISSUE_CREATE_SIDE_PANEL_WIDTH,
	ISSUE_CREATE_SIDE_PANEL_ID,
	ISSUE_CREATE_SIDE_PANEL_WIDTH,
	constructInsertStorageFragmentRequestPayload,
	getValidRange,
} from '@confluence/issue-create-side-panel';
import type { RightSidebarContextType } from '@confluence/page-layout-context';

import { convertSelectionToLegacyFormat } from './convertSelectionToLegacyFormat';

const getHTMLString = (activeSelection: Selection | null | undefined): string => {
	if (!activeSelection) {
		return '';
	}

	const docFragment = activeSelection.getRangeAt(0).cloneContents();
	const tempDiv = document.createElement('div');
	tempDiv.appendChild(docFragment);

	return tempDiv.innerHTML;
};

export const SUMMARY_MAX_LENGTH = 254;

export const splitText = (text: string, maxLength: number): [string, string, string] => {
	const horizontalEllipsis = '…';
	if (text.length <= maxLength) {
		return [text, '', ''];
	}
	// Calculate the length of the first part without including the ellipsis
	const firstPartLength = maxLength - horizontalEllipsis.length;
	// Split the text into three parts
	const firstPart = text.substring(0, firstPartLength);
	const firstPartWithHorizontalEllipis = firstPart + horizontalEllipsis;
	const secondPart = text.substring(firstPartLength);
	return [firstPartWithHorizontalEllipis, firstPart, secondPart];
};

export const getSummaryAndDescription = (
	activeSelection: Selection | null | undefined,
): [string, string, string] => {
	const selectionString = activeSelection?.toString() || '';

	const [summary, descriptionPt1, descriptionPt2] = splitText(selectionString, SUMMARY_MAX_LENGTH);
	/*
		   The regex matches one or more occurrences of any line break (\r, \n) or tab (\t)
		   and replaces them all with a single space. (We only consider whitespace characters because
		   these are the only ones that ultimately stick when parsing the summary.)
		*/
	const summaryWithoutFormatting = summary.replace(/[\r\n\t]+/g, ' ');

	return [summaryWithoutFormatting, descriptionPt1, descriptionPt2];
};

export interface CreateSingleJiraIssueHandleParams {
	rightSidebarContext: RightSidebarContextType;
	actions: IssueCreateSidePanelActions;
	activeSelection?: Selection | null;
	contentId: string;
	contentType?: ContentType;
	container: HTMLElement;
	lastModified?: string | null;
	isCreatedWithAi?: boolean;
}

export interface CreateSingleAiJiraIssueHandleParams {
	rightSidebarContext: RightSidebarContextType;
	actions: IssueCreateSidePanelActions;
	activeSelection?: Selection | null;
	contentId: string;
	contentType?: ContentType;
	container: HTMLElement;
	fireAiAnalytics: () => void;
	lastModified?: string | null;
	pressTracing?: (timeStamp?: number) => void;
}

export interface CreateBulkJiraIssuesHandleParams {
	rightSidebarContext: RightSidebarContextType;
	pressTracing?: (timeStamp?: number) => void;
}

const createSingleJiraIssueHandleCommon = ({
	actions,
	activeSelection,
	contentId,
	contentType,
	container,
	lastModified,
}: Omit<CreateSingleJiraIssueHandleParams, 'rightSidebarContext'>) => {
	const highlightedText = fg('confluence_ai_highlighted_text_using_adf')
		? htmlToADF(getHTMLString(activeSelection))
		: doc(p(activeSelection?.toString() || ''));

	const insertStorageFragmentRequestPayload = constructInsertStorageFragmentRequestPayload(
		contentId,
		convertSelectionToLegacyFormat,
		fg('confluence_issue_create_new_insertion_flow')
			? getValidRange(activeSelection?.getRangeAt(0))
			: activeSelection?.getRangeAt(0),
		container,
		lastModified,
	);
	const [summary, descriptionPt1, descriptionPt2] = getSummaryAndDescription(activeSelection);

	void actions.setSummary(summary);
	void actions.setDescriptionAdf(doc(p(descriptionPt1 + descriptionPt2)));
	void actions.setContentId(contentId);
	contentType && void actions.setContentType(contentType);
	void actions.setHighlightedText(highlightedText);
	void actions.setInsertStorageFragmentRequestPayload(insertStorageFragmentRequestPayload);
};

export const createSingleJiraIssueHandle = ({
	rightSidebarContext,
	actions,
	activeSelection,
	contentId,
	contentType,
	container,
	lastModified,
}: CreateSingleJiraIssueHandleParams) => {
	createSingleJiraIssueHandleCommon({
		actions,
		activeSelection,
		contentId,
		contentType,
		container,
		lastModified,
	});
	void actions.setIsCreatedWithAi(false);

	rightSidebarContext.open(ISSUE_CREATE_SIDE_PANEL_ID, ISSUE_CREATE_SIDE_PANEL_WIDTH);
};

export const createSingleAiJiraIssueHandle = ({
	rightSidebarContext,
	actions,
	activeSelection,
	contentId,
	contentType,
	container,
	fireAiAnalytics,
	lastModified,
	pressTracing,
}: CreateSingleAiJiraIssueHandleParams) => {
	fireAiAnalytics();
	createSingleJiraIssueHandleCommon({
		actions,
		activeSelection,
		contentId,
		contentType,
		container,
		lastModified,
	});
	void actions.setIsCreatedWithAi(true);

	rightSidebarContext.open(ISSUE_CREATE_SIDE_PANEL_ID, ISSUE_CREATE_SIDE_PANEL_WIDTH);
	pressTracing?.();
};

export const createMultipleJiraIssuesHandleModernized = ({
	rightSidebarContext,
	pressTracing,
}: CreateBulkJiraIssuesHandleParams) => {
	rightSidebarContext.open(BULK_ISSUE_CREATE_SIDE_PANEL_ID, BULK_ISSUE_CREATE_SIDE_PANEL_WIDTH);
	pressTracing?.();
};
